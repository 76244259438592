// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Env from "../../../../../../libs/Env.res.js";
import * as Url from "../../../../../../utils/Url.res.js";
import * as React from "react";
import * as Button from "../../../../../../styleguide/components/Button/Button.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as SignInModalContainer from "../../../../../../styleguide/modals/SignInModal/SignInModalContainer.res.js";
import * as ZenlayerRedirectModal from "../redirect-modal/ZenlayerRedirectModal.res.js";

function DeployNowButtonBareMetalZenlayer(props) {
  var userData = props.userData;
  var isUserLoggedIn = props.isUserLoggedIn;
  var match = React.useState(function () {
        return false;
      });
  var setIsZenlayerRedirectModalOpen = match[1];
  var match$1 = React.useState(function () {
        return false;
      });
  var setIsLoginModalOpen = match$1[1];
  var redirectToZenlayerLogin = function (userEmail) {
    if (Env.zenlayerRedirectLoginUrl === undefined) {
      return ;
    }
    var newUrl = new URL(Env.zenlayerRedirectLoginUrl);
    newUrl.searchParams.set("email", userEmail);
    Url.visit(newUrl.href);
  };
  var redirectToZenlayerSignup = function (userEmail) {
    if (Env.zenlayerRedirectSignupUrl === undefined) {
      return ;
    }
    var newUrl = new URL(Env.zenlayerRedirectSignupUrl);
    newUrl.searchParams.set("email", userEmail);
    Url.visit(newUrl.href);
  };
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(Button.make, {
                      size: props.size,
                      color: "Primary",
                      expanded: props.expanded,
                      onClick: (function (_event) {
                          if (isUserLoggedIn && userData !== undefined) {
                            var match = userData.zenlayerAccountStatus;
                            switch (match) {
                              case "Active" :
                                  return redirectToZenlayerLogin(userData.email);
                              case "InProgress" :
                                  return redirectToZenlayerSignup(userData.email);
                              case "Inactive" :
                                  return setIsZenlayerRedirectModalOpen(function (param) {
                                              return true;
                                            });
                              
                            }
                          }
                          setIsLoginModalOpen(function (param) {
                                return true;
                              });
                        }),
                      children: "Deploy Now"
                    }),
                userData !== undefined && match[0] ? JsxRuntime.jsx(ZenlayerRedirectModal.make, {
                        onClose: (function (param) {
                            setIsZenlayerRedirectModalOpen(function (param) {
                                  return false;
                                });
                          }),
                        email: userData.email,
                        firstName: userData.firstName,
                        lastName: userData.lastName,
                        onLogin: (function () {
                            redirectToZenlayerLogin(userData.email);
                          }),
                        onSignup: (function () {
                            redirectToZenlayerSignup(userData.email);
                          })
                      }) : null,
                match$1[0] ? JsxRuntime.jsx(SignInModalContainer.make, {
                        close: (function () {
                            setIsLoginModalOpen(function (param) {
                                  return false;
                                });
                          }),
                        callback: (function () {
                            setIsLoginModalOpen(function (param) {
                                  return false;
                                });
                            setIsZenlayerRedirectModalOpen(function (param) {
                                  return true;
                                });
                          }),
                        setUserData: props.setUserData,
                        subTitle: "Sign in to deploy your Bare Metal service and get VIP Access."
                      }) : null
              ]
            });
}

var make = DeployNowButtonBareMetalZenlayer;

export {
  make ,
}
/* Env Not a pure module */
