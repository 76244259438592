import * as React from 'react';
import ReduxProvider from 'ReduxProvider';
import { connect } from 'react-redux';
import { fromJs } from '../../../../rescript/models/User.res.js';

import UserCloudRack from './UserCloudRack.res.js';

const mapStateToProps = state => ({
  userData: fromJs(state.user),
});

const UserCloudRackContainer = connect(mapStateToProps)(UserCloudRack);

export default props => (
  <ReduxProvider {...props}>
    <UserCloudRackContainer />
  </ReduxProvider>
);
