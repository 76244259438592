// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Link from "../../../styleguide/components/Link/Link.res.js";
import * as IconChevronDown from "../../../styleguide/icons/IconChevronDown.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as UserCloudRackManageButtonScss from "./UserCloudRackManageButton.scss";

var css = UserCloudRackManageButtonScss;

function UserCloudRackManageButton(props) {
  return JsxRuntime.jsxs(Link.make, {
              href: props.href,
              className: css.link,
              children: [
                "MANAGE",
                JsxRuntime.jsx("span", {
                      children: JsxRuntime.jsx(IconChevronDown.make, {
                            size: "MD",
                            color: "Teal"
                          }),
                      className: css.icon
                    })
              ]
            });
}

var make = UserCloudRackManageButton;

export {
  css ,
  make ,
}
/* css Not a pure module */
