// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Link from "../../../styleguide/components/Link/Link.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as UserCloudRackProviderScss from "./UserCloudRackProvider.scss";

var css = UserCloudRackProviderScss;

function UserCloudRackProvider(props) {
  var link = props.link;
  var logo = props.logo;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        link !== undefined ? JsxRuntime.jsx("div", {
                                children: JsxRuntime.jsx(Link.make, {
                                      href: link,
                                      children: JsxRuntime.jsx("img", {
                                            height: "36px",
                                            src: logo
                                          })
                                    }),
                                className: css.cloudRackImage
                              }) : JsxRuntime.jsx("div", {
                                children: JsxRuntime.jsx("img", {
                                      height: "36px",
                                      src: logo
                                    }),
                                className: css.cloudRackImage
                              }),
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx("div", {
                                      children: props.title,
                                      className: css.cloudRackTitle
                                    }),
                                JsxRuntime.jsx("div", {
                                      children: props.provider,
                                      className: css.cloudRackProvider
                                    })
                              ],
                              className: css.cloudRackColumn
                            })
                      ],
                      className: css.cloudRackRow
                    }),
                props.actionControlComponent
              ],
              className: css.cloudRackWrapper
            });
}

var make = UserCloudRackProvider;

export {
  css ,
  make ,
}
/* css Not a pure module */
