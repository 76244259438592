// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Env from "../../../libs/Env.res.js";
import * as TopNavbar from "../top-navbar/TopNavbar.res.js";
import * as Routes_Page from "../../../routes/common/Routes_Page.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as UserCloudRackScss from "./UserCloudRack.scss";
import * as UserCloudRackProvider from "./UserCloudRackProvider.res.js";
import * as DeployNowButtonMulticloud from "../../services/multicloud/components/deploy-now-button/DeployNowButtonMulticloud.res.js";
import * as UserCloudRackManageButton from "./UserCloudRackManageButton.res.js";
import * as DeployNowButtonBareMetalZenlayer from "../../services/bare-metal-cloud/zenlayer/components/deploy-now-button/DeployNowButtonBareMetalZenlayer.res.js";
import CircleSvg from "Images/companies/zenlayer/circle.svg";
import LogoControlPlaneSvg from "Images/companies/logo-control-plane.svg";

var css = UserCloudRackScss;

function UserCloudRack(props) {
  var userData = props.userData;
  var zenlayerAccountStatus = userData !== undefined ? userData.zenlayerAccountStatus : "Inactive";
  var controlPlaneAccountStatus = userData !== undefined ? userData.controlPlaneAccountStatus : "Inactive";
  var tmp;
  var exit = 0;
  switch (controlPlaneAccountStatus) {
    case "Active" :
        tmp = JsxRuntime.jsx(UserCloudRackManageButton.make, {
              href: Env.controlPlaneRedirectLoginUrl !== undefined ? Env.controlPlaneRedirectLoginUrl : "#"
            });
        break;
    case "InProgress" :
    case "Inactive" :
        exit = 1;
        break;
    
  }
  if (exit === 1) {
    tmp = JsxRuntime.jsx(DeployNowButtonMulticloud.make, {
          isUserLoggedIn: true,
          userData: userData,
          setUserData: (function (param) {
              
            }),
          size: "SM",
          expanded: false
        });
  }
  var tmp$1;
  var exit$1 = 0;
  switch (zenlayerAccountStatus) {
    case "Active" :
        tmp$1 = JsxRuntime.jsx(UserCloudRackManageButton.make, {
              href: Env.zenlayerRedirectLoginUrl !== undefined ? Env.zenlayerRedirectLoginUrl : "#"
            });
        break;
    case "InProgress" :
    case "Inactive" :
        exit$1 = 1;
        break;
    
  }
  if (exit$1 === 1) {
    tmp$1 = JsxRuntime.jsx(DeployNowButtonBareMetalZenlayer.make, {
          isUserLoggedIn: true,
          userData: userData,
          setUserData: (function (param) {
              
            }),
          size: "SM",
          expanded: false
        });
  }
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(TopNavbar.make, {
                      selectedCategory: "CloudRack"
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("h1", {
                              children: "CloudRack™ Control Center",
                              className: css.titleMain
                            }),
                        JsxRuntime.jsx("div", {
                              children: "Quick and easy access to all your cloud infrastructure through DataCenters.com CloudRack™ Control Center.",
                              className: css.description
                            }),
                        JsxRuntime.jsx(UserCloudRackProvider.make, {
                              title: "Multicloud",
                              provider: "Control Plane",
                              logo: LogoControlPlaneSvg,
                              actionControlComponent: tmp,
                              link: Routes_Page.controlPlaneSignUp
                            }),
                        JsxRuntime.jsx(UserCloudRackProvider.make, {
                              title: "Bare Metal",
                              provider: "Zenlayer",
                              logo: CircleSvg,
                              actionControlComponent: tmp$1
                            })
                      ],
                      className: css.contentWrapper
                    })
              ],
              className: css.container
            });
}

var make = UserCloudRack;

var $$default = UserCloudRack;

export {
  css ,
  make ,
  $$default as default,
}
/* css Not a pure module */
