// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Api from "../../../../../../api/Api.res.js";
import * as React from "react";
import * as $$Promise from "../../../../../../bindings/Promise.res.js";
import * as RedirectModal from "../../../../../../styleguide/components/RedirectModal/RedirectModal.res.js";
import * as RedirectModalForm from "../../../../../../styleguide/components/RedirectModal/form/RedirectModalForm.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as RedirectModalTitle from "../../../../../../styleguide/components/RedirectModal/RedirectModalTitle.res.js";
import * as RedirectModalFormCell from "../../../../../../styleguide/components/RedirectModal/form/RedirectModalFormCell.res.js";
import ZenlayerSvg from "../../assets/zenlayer.svg";

var imageZenlayerLogo = ZenlayerSvg;

function ZenlayerRedirectModal(props) {
  var onSignup = props.onSignup;
  var onLogin = props.onLogin;
  var match = React.useState(function () {
        return false;
      });
  var setHasError = match[1];
  return JsxRuntime.jsx(RedirectModal.make, {
              onCloseButtonClick: props.onClose,
              onCreateAccountButtonClick: (function (param) {
                  $$Promise.wait(Api.zenlayerRegistrationRequest(), (function (response) {
                          if (response.TAG !== "Ok") {
                            return setHasError(function (param) {
                                        return true;
                                      });
                          }
                          var match = response._0.message;
                          switch (match) {
                            case "Success" :
                                return onSignup();
                            case "UserExists" :
                                return onLogin();
                            case "Error" :
                                return setHasError(function (param) {
                                            return true;
                                          });
                            
                          }
                        }));
                }),
              titleComponent: JsxRuntime.jsxs(RedirectModalTitle.make, {
                    children: [
                      "Deploy Now Your Zenlayer Account with DataCenters.com",
                      JsxRuntime.jsx("span", {
                            children: "and Get Exclusive VIP Access and Pricing"
                          }),
                      "to the Newest Bare Metal Locations"
                    ]
                  }),
              formComponent: JsxRuntime.jsxs(RedirectModalForm.make, {
                    children: [
                      JsxRuntime.jsx(RedirectModalFormCell.make, {
                            children: props.email,
                            title: "Email",
                            enableReadOnlyPopup: true
                          }),
                      JsxRuntime.jsx(RedirectModalFormCell.make, {
                            children: props.firstName,
                            title: "First Name",
                            enableReadOnlyPopup: true
                          }),
                      JsxRuntime.jsx(RedirectModalFormCell.make, {
                            children: props.lastName,
                            title: "Last Name",
                            enableReadOnlyPopup: true
                          })
                    ]
                  }),
              providerLogo: imageZenlayerLogo,
              hasError: match[0],
              modalType: "BareMetalCloud"
            });
}

var make = ZenlayerRedirectModal;

export {
  imageZenlayerLogo ,
  make ,
}
/* imageZenlayerLogo Not a pure module */
